import { Routes } from '@angular/router';
var ɵ0 = function () { return import("./pages/home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); }, ɵ1 = function () { return import("./pages/faqs/faqs.module.ngfactory").then(function (m) { return m.FaqsModuleNgFactory; }); };
var routes = [
    { path: 'home', loadChildren: ɵ0 },
    { path: 'faqs', loadChildren: ɵ1 },
    { path: '**', redirectTo: 'home', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
