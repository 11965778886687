/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../user-auth/buttons/login-btn/login-btn.component.ngfactory";
import * as i4 from "../user-auth/buttons/login-btn/login-btn.component";
import * as i5 from "amg-fe-angular/src/app/core/services/user-auth/user-auth.service";
import * as i6 from "amg-fe-angular/src/app/core/pipes/is-external-link.pipe";
import * as i7 from "../../pipe/label.pipe";
import * as i8 from "../../../core/services/wordpress.service";
import * as i9 from "./header.component";
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [], [[1, "href", 4], [8, "textContent", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.link; var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], [[8, "textContent", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_HeaderComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵand(0, [["internal", 2]], null, 0, null, View_HeaderComponent_3))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _v.context.$implicit.link)); var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_HeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-login-btn", [["className", "btn-dark"]], null, null, null, i3.View_LoginBtnComponent_0, i3.RenderType_LoginBtnComponent)), i0.ɵdid(2, 114688, null, 0, i4.LoginBtnComponent, [i5.UserAuthService], { className: [0, "className"] }, null)], function (_ck, _v) { var currVal_0 = "btn-dark"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [], [[8, "href", 4], [8, "textContent", 0]], null, null, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "button"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["class", "btn-dark"], ["href", "#"]], [[8, "textContent", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doLogout($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(6, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.myAccountLink; var currVal_1 = i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 1), "my-account")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 1), "log-out")); _ck(_v, 5, 0, currVal_2); }); }
export function View_HeaderComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.IsExternalLinkPipe, []), i0.ɵpid(131072, i7.LabelPipe, [i8.WordpressService]), (_l()(), i0.ɵeld(2, 0, null, null, 21, "header", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "a", [["routerLink", "/home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["home"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "menu-mobile-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 15, "div", [["id", "header-menu"]], [[2, "opened", null]], null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "div", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 13, "nav", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 6, "div", [["class", "home"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 5, "a", [["routerLink", "/home"], ["routerLinkActive", "selected"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(14, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, [2, i1.RouterLink], [2, i1.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["HOME"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(19, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_4)), i0.ɵdid(21, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i0.ɵdid(23, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/home"; _ck(_v, 5, 0, currVal_2); var currVal_6 = "/home"; _ck(_v, 13, 0, currVal_6); var currVal_7 = "selected"; _ck(_v, 14, 0, currVal_7); var currVal_8 = _co.commonData.menu; _ck(_v, 19, 0, currVal_8); var currVal_9 = !_co.isLoggedIn; _ck(_v, 21, 0, currVal_9); var currVal_10 = _co.isLoggedIn; _ck(_v, 23, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 5).target; var currVal_1 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = _co.menuOpened; _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 13).target; var currVal_5 = i0.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 245760, null, 0, i9.HeaderComponent, [i1.Router, i0.ChangeDetectorRef, i8.WordpressService, i5.UserAuthService, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i0.ɵccf("app-header", i9.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
