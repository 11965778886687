import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {LabelPipe} from './pipe/label.pipe';
import {TextPipe} from './pipe/text.pipe';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';

import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {LoginBtnComponent} from './components/user-auth/buttons/login-btn/login-btn.component';
import {LoginModalComponent} from './components/user-auth/login-modal/login-modal.component';
import {IsExternalLinkPipe} from 'amg-fe-angular/pipes/is-external-link.pipe';
import {DateFormattedPipe} from 'amg-fe-angular/pipes/date-formatted.pipe';


@NgModule({
  declarations: [
    LoginBtnComponent,
    LoginModalComponent,
    HeaderComponent,
    FooterComponent,
    LabelPipe,
    TextPipe,
    SafeHtmlPipe,
    IsExternalLinkPipe,
    DateFormattedPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    LoginBtnComponent,
    LoginModalComponent,
    HeaderComponent,
    FooterComponent,
    LabelPipe,
    TextPipe,
    SafeHtmlPipe,
    IsExternalLinkPipe,
    DateFormattedPipe
  ]
})
export class SharedModule {
}
