import { ChangeDetectorRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { UserState } from 'amg-fe-angular/types/payments/user-auth.type';
import { CssVarsUtils } from 'amg-fe-angular/utils/css-vars-utils';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, wordpressService, userAuthService, renderer, platformId, cd) {
        this.router = router;
        this.wordpressService = wordpressService;
        this.userAuthService = userAuthService;
        this.renderer = renderer;
        this.platformId = platformId;
        this.cd = cd;
        this.title = 'Hull';
        this.appReady = false;
        this.isHomePage = true;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // retrieve Common data
        this.wordpressService.getCommonData().subscribe(function (data) {
            if (data) {
                _this.appReady = true;
                _this.cd.markForCheck();
            }
        });
        this.userAuthService.userStateObservable.subscribe(function (userState) {
            if (userState) {
                _this.isLoggedIn = userState.state === UserState.loggedIn;
                if (_this.isLoggedIn) {
                    _this.renderer.addClass(document.body.parentElement, 'logged-in');
                }
                else {
                    _this.renderer.removeClass(document.body.parentElement, 'logged-in');
                }
            }
        });
        CssVarsUtils.initScreenSizesOnRoot();
        var currentRoute = '';
        this.routerSubscription = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationStart || event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (currentRoute === event.url.split('/')[1]) {
                    _this.renderer.addClass(document.body.parentElement, 'same-page-navigation');
                }
                else {
                    _this.renderer.removeClass(document.body.parentElement, 'same-page-navigation');
                }
            }
            else {
                currentRoute = event.urlAfterRedirects.split('/')[1];
                _this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
                _this.sendGoogleAnalyticsData(event);
            }
        });
    };
    AppComponent.prototype.setPageAttribute = function (event, eventUrlKey, htmlAttribute) {
        if (event[eventUrlKey] !== '/') {
            var urlTree = this.router.parseUrl(event[eventUrlKey]);
            var page = urlTree.root.children.primary.segments.map(function (it) { return it.path; }).join('/');
            this.renderer.setAttribute(document.body.parentElement, htmlAttribute, page);
            this.isHomePage = (page === 'home');
        }
    };
    AppComponent.prototype.sendGoogleAnalyticsData = function (event) {
        if (environment.google.analyticsId && isPlatformBrowser(this.platformId)) {
            window.gtag('config', environment.google.analyticsId, {
                page_path: event.urlAfterRedirects
            });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.routerSubscription.unsubscribe();
    };
    return AppComponent;
}());
export { AppComponent };
