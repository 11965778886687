import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { configs } from '../configs';
import { WordpressBaseService } from 'amg-fe-angular/services/wordpress-base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WordpressService = /** @class */ (function (_super) {
    tslib_1.__extends(WordpressService, _super);
    function WordpressService(httpClient) {
        var _this = _super.call(this, httpClient, "" + environment.wordpress.api + configs.wordpress.commonData, environment.wordpress.api) || this;
        _this.cacheLabels = {};
        return _this;
    }
    WordpressService.prototype.getLabels = function (propertyName) {
        var _this = this;
        if (!this.cacheLabels[propertyName]) {
            this.cacheLabels[propertyName] = new ReplaySubject(1);
            this.getData(this.commonDataUrl).subscribe(function (value) {
                if (value && value[propertyName]) {
                    var labels_1 = {};
                    value[propertyName].forEach(function (element) {
                        labels_1[element.key] = element.value;
                    });
                    _this.cacheLabels[propertyName].next(labels_1);
                }
            });
        }
        return this.cacheLabels[propertyName].asObservable();
    };
    WordpressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WordpressService_Factory() { return new WordpressService(i0.ɵɵinject(i1.HttpClient)); }, token: WordpressService, providedIn: "root" });
    return WordpressService;
}(WordpressBaseService));
export { WordpressService };
