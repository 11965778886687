import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy, Renderer2, HostListener
} from '@angular/core';
import {CommonData} from '../../../core/types/wordpress/common-data.type';
import {WordpressService} from '../../../core/services/wordpress.service';
import {Subscription} from 'rxjs';
import {configs} from '../../../core/configs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {UserState} from 'amg-fe-angular/types/payments/user-auth.type';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private commonDataSubscription: Subscription;
  public commonData: CommonData;
  public isLoggedIn: boolean;
  public menuOpened = false;
  public myAccountLink = configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.myAccount;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private wordpressService: WordpressService,
    public userAuthService: UserAuthService,
    private renderer: Renderer2) {
  }

  ngOnInit() {

    this.commonDataSubscription = this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      });

    this.userAuthService.userStateObservable.subscribe(userState => {

      if (userState) {
        this.isLoggedIn = userState.state !== UserState.notLoggedIn;
        this.cd.markForCheck();
      }
    });

    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      });
  }


  public doLogout(event) {

    event.preventDefault();
    this.userAuthService.streamPaymentsLogOut();
  }

  public openMenu() {
    this.menuOpened = true;
    //  window.scrollTo(0, 0);
    this.renderer.addClass(document.body, 'overlay-opened');
    // trigger refresh html...
    this.cd.markForCheck();
  }

  public closeMenu() {
    this.menuOpened = false;
    this.renderer.removeClass(document.body, 'overlay-opened');
    // trigger refresh html...
    this.cd.markForCheck();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth >= 768 && this.menuOpened) {
      this.closeMenu();
    }
  }

  ngOnDestroy(): void {
    this.commonDataSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
