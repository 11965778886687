// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  fixtures: {
    /*
    hull: 3000101
    leeds: 3000582
     */
    url: 'https://staging.api.streamplay.streamamg.com/fixtures/football/p/3000101/',
    contentPageSize: 6
  },

  google: {
    analyticsId: ''
  },

  wordpress: {
    api: 'https://wp-devhull.streamamg.com/wp-json/wpa/v1',
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devhull.streamamg.com',
  cloudPayDomain: 'hullcitytigers-payments-staging.streamamg.com'
};
