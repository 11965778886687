import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {WordpressService} from './core/services/wordpress.service';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../environments/environment';
import {filter} from 'rxjs/operators';
import {UserState} from 'amg-fe-angular/types/payments/user-auth.type';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';
import {CssVarsUtils} from 'amg-fe-angular/utils/css-vars-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Hull';
  public isLoggedIn: boolean;
  public appReady = false;
  public isHomePage = true;

  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private wordpressService: WordpressService,
    public userAuthService: UserAuthService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    private cd: ChangeDetectorRef) {

  }


  ngOnInit(): void {
    // retrieve Common data
    this.wordpressService.getCommonData().subscribe(data => {
      if (data) {
        this.appReady = true;
        this.cd.markForCheck();
      }
    });

    this.userAuthService.userStateObservable.subscribe(userState => {
      if (userState) {
        this.isLoggedIn = userState.state === UserState.loggedIn;

        if (this.isLoggedIn) {
          this.renderer.addClass(document.body.parentElement, 'logged-in');
        } else {
          this.renderer.removeClass(document.body.parentElement, 'logged-in');
        }
      }
    });


    CssVarsUtils.initScreenSizesOnRoot();


    let currentRoute = '';
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe((event: NavigationStart | NavigationEnd) => {

        if (event instanceof NavigationStart) {
          if (currentRoute === event.url.split('/')[1]) {
            this.renderer.addClass(document.body.parentElement, 'same-page-navigation');
          } else {
            this.renderer.removeClass(document.body.parentElement, 'same-page-navigation');
          }
        } else {
          currentRoute = event.urlAfterRedirects.split('/')[1];
          this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');

          this.sendGoogleAnalyticsData(event);

        }
      });
  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(document.body.parentElement, htmlAttribute, page);

      this.isHomePage = (page === 'home');
    }
  }

  private sendGoogleAnalyticsData(event: NavigationEnd): void {

    if (environment.google.analyticsId && isPlatformBrowser(this.platformId)) {
      (window as any).gtag('config', environment.google.analyticsId, {
        page_path: event.urlAfterRedirects
      });
    }
  }


  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }
}
