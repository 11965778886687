import { OnDestroy, PipeTransform } from '@angular/core';
var LabelPipe = /** @class */ (function () {
    function LabelPipe(wordpressService) {
        var _this = this;
        this.wordpressService = wordpressService;
        this.labels = {};
        this.labelsSubscription = this.wordpressService.getLabels('labels').subscribe(function (data) {
            if (data) {
                _this.labels = data;
            }
        });
    }
    LabelPipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return this.labels[value] || value;
    };
    LabelPipe.prototype.ngOnDestroy = function () {
        this.labelsSubscription.unsubscribe();
    };
    return LabelPipe;
}());
export { LabelPipe };
