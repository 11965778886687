export const configs = {

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3000101',
    uiConfId: '30023347',
    uiConfIdDRM: '30025950'
  },

  streamPayments: {
    // hullcitytigers-payments-staging
    //
    protocol: 'https://',
    urls: {
      myAccount: '/account',
      reset: '/account/reset',
      registration: '/account/freeregistration'
    }
  },

  wordpress: {
    pages: {
      faq: '/section?slug=faq',
      packages: '/section?slug=packages',
    },
    commonData: '/common_field?slug=common-fields'
  }
};
