import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { configs } from '../../../../core/configs';
import { LoginModalBaseComponent } from 'amg-fe-angular/modules/login-modal/login-modal-base.component';
import { environment } from '../../../../../environments/environment';
var LoginModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LoginModalComponent, _super);
    function LoginModalComponent(wordpressService, userAuthService, formBuilder, cd) {
        var _this = _super.call(this, userAuthService, formBuilder, cd, configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.registration, configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.reset) || this;
        _this.wordpressService = wordpressService;
        return _this;
    }
    LoginModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        _super.prototype.openModalBind.call(this);
        this.wordpressSubscription = this.wordpressService.getLabels('labels').subscribe(function (labels) {
            if (labels) {
                _this.texts = tslib_1.__assign({}, _this.texts, { errors: {
                        email: {
                            required: labels['login.error.email-required'],
                            pattern: labels['login.invalid-email']
                        },
                        password: {
                            required: labels['login.error.pwd-required'],
                            minLength: labels['login.error.pwd-length']
                        }
                    }, submit: labels.login, forgotPassword: labels['login.forgot.pwd'], newAccount: labels['login.create.account'] });
            }
        });
    };
    LoginModalComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.wordpressSubscription) {
            this.wordpressSubscription.unsubscribe();
        }
    };
    return LoginModalComponent;
}(LoginModalBaseComponent));
export { LoginModalComponent };
