import { OnInit } from '@angular/core';
var LoginBtnComponent = /** @class */ (function () {
    function LoginBtnComponent(userAuthService) {
        this.userAuthService = userAuthService;
        this.className = 'btn btn-dark';
    }
    LoginBtnComponent.prototype.ngOnInit = function () {
    };
    LoginBtnComponent.prototype.openLoginModal = function (event) {
        event.preventDefault();
        this.userAuthService.openLoginModal();
    };
    return LoginBtnComponent;
}());
export { LoginBtnComponent };
