import { ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { UrlUtils } from 'amg-fe-angular/utils/url-utils';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(cd, wordpressService) {
        this.cd = cd;
        this.wordpressService = wordpressService;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.wordpressSubscription = this.wordpressService.getCommonData()
            .subscribe(function (data) {
            if (data) {
                _this.commonData = data;
                _this.cd.markForCheck();
            }
        });
    };
    FooterComponent.prototype.social = function (link) {
        return UrlUtils.generateClassNameFromDomainName(link);
    };
    FooterComponent.prototype.ngOnDestroy = function () {
        this.wordpressSubscription.unsubscribe();
    };
    return FooterComponent;
}());
export { FooterComponent };
