import { ChangeDetectorRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { configs } from '../../../core/configs';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserState } from 'amg-fe-angular/types/payments/user-auth.type';
import { environment } from '../../../../environments/environment';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, cd, wordpressService, userAuthService, renderer) {
        this.router = router;
        this.cd = cd;
        this.wordpressService = wordpressService;
        this.userAuthService = userAuthService;
        this.renderer = renderer;
        this.menuOpened = false;
        this.myAccountLink = configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.myAccount;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.commonDataSubscription = this.wordpressService.getCommonData()
            .subscribe(function (data) {
            if (data) {
                _this.commonData = data;
                _this.cd.markForCheck();
            }
        });
        this.userAuthService.userStateObservable.subscribe(function (userState) {
            if (userState) {
                _this.isLoggedIn = userState.state !== UserState.notLoggedIn;
                _this.cd.markForCheck();
            }
        });
        this.routerSubscription = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.closeMenu();
        });
    };
    HeaderComponent.prototype.doLogout = function (event) {
        event.preventDefault();
        this.userAuthService.streamPaymentsLogOut();
    };
    HeaderComponent.prototype.openMenu = function () {
        this.menuOpened = true;
        //  window.scrollTo(0, 0);
        this.renderer.addClass(document.body, 'overlay-opened');
        // trigger refresh html...
        this.cd.markForCheck();
    };
    HeaderComponent.prototype.closeMenu = function () {
        this.menuOpened = false;
        this.renderer.removeClass(document.body, 'overlay-opened');
        // trigger refresh html...
        this.cd.markForCheck();
    };
    HeaderComponent.prototype.onResize = function (event) {
        if (window.innerWidth >= 768 && this.menuOpened) {
            this.closeMenu();
        }
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.commonDataSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
