/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../pipe/label.pipe";
import * as i2 from "../../../../../core/services/wordpress.service";
import * as i3 from "./login-btn.component";
import * as i4 from "amg-fe-angular/src/app/core/services/user-auth/user-auth.service";
var styles_LoginBtnComponent = [];
var RenderType_LoginBtnComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginBtnComponent, data: {} });
export { RenderType_LoginBtnComponent as RenderType_LoginBtnComponent };
export function View_LoginBtnComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(131072, i1.LabelPipe, [i2.WordpressService]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["href", "#"]], [[8, "className", 0], [8, "textContent", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLoginModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v, 0), "login")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_LoginBtnComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-login-btn", [], null, null, null, View_LoginBtnComponent_0, RenderType_LoginBtnComponent)), i0.ɵdid(1, 114688, null, 0, i3.LoginBtnComponent, [i4.UserAuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginBtnComponentNgFactory = i0.ɵccf("app-login-btn", i3.LoginBtnComponent, View_LoginBtnComponent_Host_0, { className: "className" }, {}, []);
export { LoginBtnComponentNgFactory as LoginBtnComponentNgFactory };
