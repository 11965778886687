import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';

@Component({
  selector: 'app-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBtnComponent implements OnInit {
  @Input() className = 'btn btn-dark';

  constructor(private userAuthService: UserAuthService) {
  }

  ngOnInit() {

  }

  public openLoginModal(event): void {

    event.preventDefault();

    this.userAuthService.openLoginModal();
  }
}
