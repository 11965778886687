<footer>
  <div class="top">
    <div class="logo"><a routerLink="/home">home</a></div>
    <div class="social-links">
      <div *ngFor="let item of commonData.social_links">
        <a [attr.href]="item.link" target="_blank" [ngClass]="social(item.link)" [textContent]="social(item.link)"></a>
      </div>
    </div>
  </div>
  <div class="links">
    <div *ngFor="let item of commonData.footer_links">
      <ng-container *ngIf="item.link | isExternalLink; else internal">
        <a [attr.href]="item.link" [textContent]="item.label"></a>
      </ng-container>
      <ng-template #internal>
        <a [routerLink]="item.link" [textContent]="item.label"></a>
      </ng-template>
    </div>
  </div>
</footer>
