import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy
} from '@angular/core';
import {CommonData} from '../../../core/types/wordpress/common-data.type';
import {WordpressService} from '../../../core/services/wordpress.service';
import {Subscription} from 'rxjs';
import {UrlUtils} from 'amg-fe-angular/utils/url-utils';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  private wordpressSubscription: Subscription;
  public commonData: CommonData;

  constructor(private cd: ChangeDetectorRef, private wordpressService: WordpressService) {
  }

  ngOnInit() {

    this.wordpressSubscription = this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      });
  }

  public social(link): string {
    return UrlUtils.generateClassNameFromDomainName(link);
  }

  ngOnDestroy(): void {
    this.wordpressSubscription.unsubscribe();
  }
}
