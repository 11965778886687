<ng-container *ngIf="appReady">

  <app-header></app-header>

  <div id="page-container">
    <router-outlet></router-outlet>
  </div>
  <app-footer *ngIf="!isHomePage"></app-footer>
  <app-login-modal></app-login-modal>
</ng-container>
