import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CommonData} from '../types/wordpress/common-data.type';
import {Labels} from '../types/labels.type';
import {configs} from '../configs';
import {WordpressBaseService} from 'amg-fe-angular/services/wordpress-base.service';

@Injectable({
  providedIn: 'root'
})
export class WordpressService extends WordpressBaseService {

  private cacheLabels: { [t: string]: ReplaySubject<Labels> };

  constructor(httpClient: HttpClient) {
    super(httpClient, `${environment.wordpress.api}${configs.wordpress.commonData}`, environment.wordpress.api);
    this.cacheLabels = {};
  }


  public getLabels(propertyName: 'labels' | 'texts'): Observable<Labels> {
    if (!this.cacheLabels[propertyName]) {

      this.cacheLabels[propertyName] = new ReplaySubject<Labels>(1);

      this.getData<CommonData>(this.commonDataUrl).subscribe((value: CommonData) => {

        if (value && value[propertyName]) {

          const labels: Labels = {};

          value[propertyName].forEach((element) => {

            labels[element.key] = element.value;
          });

          this.cacheLabels[propertyName].next(labels);
        }
      });
    }

    return this.cacheLabels[propertyName].asObservable();
  }
}
