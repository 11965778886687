/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/components/footer/footer.component.ngfactory";
import * as i2 from "./shared/components/footer/footer.component";
import * as i3 from "./core/services/wordpress.service";
import * as i4 from "./shared/components/header/header.component.ngfactory";
import * as i5 from "./shared/components/header/header.component";
import * as i6 from "@angular/router";
import * as i7 from "amg-fe-angular/src/app/core/services/user-auth/user-auth.service";
import * as i8 from "@angular/common";
import * as i9 from "./shared/components/user-auth/login-modal/login-modal.component.ngfactory";
import * as i10 from "./shared/components/user-auth/login-modal/login-modal.component";
import * as i11 from "@angular/forms";
import * as i12 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, i1.View_FooterComponent_0, i1.RenderType_FooterComponent)), i0.ɵdid(1, 245760, null, 0, i2.FooterComponent, [i0.ChangeDetectorRef, i3.WordpressService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-header", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_HeaderComponent_0, i4.RenderType_HeaderComponent)), i0.ɵdid(2, 245760, null, 0, i5.HeaderComponent, [i6.Router, i0.ChangeDetectorRef, i3.WordpressService, i7.UserAuthService, i0.Renderer2], null, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["id", "page-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i0.ɵdid(7, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-login-modal", [], null, null, null, i9.View_LoginModalComponent_0, i9.RenderType_LoginModalComponent)), i0.ɵdid(9, 245760, null, 0, i10.LoginModalComponent, [i3.WordpressService, i7.UserAuthService, i11.FormBuilder, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); _ck(_v, 5, 0); var currVal_0 = !_co.isHomePage; _ck(_v, 7, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appReady; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i12.AppComponent, [i6.Router, i3.WordpressService, i7.UserAuthService, i0.Renderer2, i0.PLATFORM_ID, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
