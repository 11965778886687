import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnInit, OnDestroy
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {configs} from '../../../../core/configs';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';
import {WordpressService} from '../../../../core/services/wordpress.service';
import {Subscription} from 'rxjs';
import {LoginModalBaseComponent} from 'amg-fe-angular/modules/login-modal/login-modal-base.component';
import {AmgModulesPathGenerator} from 'amg-fe-angular/utils/amg-modules-path-generator';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-login-modal',
  templateUrl: AmgModulesPathGenerator.deep6('login-modal/login-modal.component.html'),
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginModalComponent extends LoginModalBaseComponent implements OnInit, OnDestroy {

  private wordpressSubscription: Subscription;

  constructor(
    private wordpressService: WordpressService,
    userAuthService: UserAuthService,
    formBuilder: FormBuilder,
    cd: ChangeDetectorRef) {

    super(
      userAuthService,
      formBuilder,
      cd,
      configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.registration,
      configs.streamPayments.protocol + environment.cloudPayDomain + configs.streamPayments.urls.reset
    );
  }

  ngOnInit() {
    super.ngOnInit();

    super.openModalBind();

    this.wordpressSubscription = this.wordpressService.getLabels('labels').subscribe((labels) => {

      if (labels) {

        this.texts = {
          ...this.texts,
          errors: {
            email: {
              required: labels['login.error.email-required'],
              pattern: labels['login.invalid-email']
            },
            password: {
              required: labels['login.error.pwd-required'],
              minLength: labels['login.error.pwd-length']
            }
          },
          submit: labels.login,
          forgotPassword: labels['login.forgot.pwd'],
          newAccount: labels['login.create.account']
        };
      }
    });
  }

  ngOnDestroy(): void {

    super.ngOnDestroy();

    if (this.wordpressSubscription) {
      this.wordpressSubscription.unsubscribe();
    }
  }
}
