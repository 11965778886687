<header> <!-- [class.show-menu-mobile]="commonData.menu && commonData.menu.length > 1 || isLoggedIn" -->

  <div class="logo">
    <a routerLink="/home">home</a>
  </div>
  <div (click)="openMenu()" class="menu-mobile-icon"></div>
  <div id="header-menu" [class.opened]="menuOpened">
    <div (click)="closeMenu()" class="close-btn"></div>
    <nav>
      <div class="home">
        <a routerLinkActive="selected" routerLink="/home">HOME</a>
      </div>
      <div *ngFor="let item of commonData.menu">

        <ng-container *ngIf="item.link | isExternalLink; else internal">
          <a [attr.href]="item.link" [textContent]="item.label"></a>
        </ng-container>
        <ng-template #internal>
          <a [routerLink]="item.link" [textContent]="item.label"></a>
        </ng-template>
      </div>

      <div *ngIf="!isLoggedIn" class="button">
        <app-login-btn className="btn-dark"></app-login-btn>
      </div>
      <ng-container *ngIf="isLoggedIn">

        <div class="button">
          <a [href]="myAccountLink" [textContent]="'my-account' | label"></a>
        </div>
        <div class="button">
          <a class="btn-dark" (click)="doLogout($event)" href="#" [textContent]="'log-out' | label"></a>
        </div>
      </ng-container>

    </nav>
  </div>

</header>
